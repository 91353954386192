import { Text } from "@chakra-ui/react";
import React from "react";

function Footer() {
  return (
    <div className="w-full fixed bottom-0 flex items-center flex-col-reverse xl:flex-row px-4 xl:mt-2 xl:space-x-24">
      <p className="text-sm font-extrabold text-slate-400 mb-2 xl:mb-0">
        ©{" "}
        <span className="text-galactic_blue">
          crypto
        </span>
        <span className="text-galactic_purple">
          claims
        </span>
        <span className="text-galactic_gray">
          .wallet
        </span>
      </p>
      <p className="text-xs text-slate-400 text-center xl:max-w-5xl p-3">
        <span className="text-galactic_blue">
          Note:
        </span>{" "}
        Participation in a campaign does
        not confer or guarantee any
        rights to receive any financial
        or other benefits. All rewards
        are purely at the sole
        discretion of the campaign
        operator.
      </p>
    </div>
  );
}

export default Footer;
