import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

const avalancheChain = {
  id: Number(
    process.env
      .REACT_APP_AVALANCHE_CHAIN_ID
  ),
  name: process.env
    .REACT_APP_AVALANCHE_CHAIN_NAME,
  network:
    process.env
      .REACT_APP_AVALANCHE_CHAIN_NAME,

  nativeCurrency: {
    decimals: 18,
    name: process.env
      .REACT_APP_AVALANCHE_CHAIN_NAME,
    symbol:
      process.env
        .REACT_APP_AVALANCHE_CHAIN_SYMBOL,
  },
  rpcUrls: {
    default: `${process.env.REACT_APP_AVALANCHE_RPC_LINK}`,
  },
  blockExplorers: {
    default: {
      name: "C-Chain",
      url: `${process.env.REACT_APP_AVALANCHE_BLOCK_EXPLORER_LINK}`,
    },
    etherscan: {
      name: "C-Chain",
      url: `${process.env.REACT_APP_AVALANCHE_BLOCK_EXPLORER_LINK}`,
    },
  },
  testnet: true,
};

const { chains, provider } =
  configureChains(
    [
      avalancheChain,
      chain.mainnet,
      chain.polygon,
      chain.optimism,
      chain.arbitrum,
    ],
    [
      jsonRpcProvider({
        rpc: (chain) => ({
          http: chain.rpcUrls.default,
        }),
      }),
    ]
  );

const { connectors } =
  getDefaultWallets({
    appName: "My RainbowKit App",
    chains,
  });

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const theme = extendTheme({
  components: {
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          bg: "#111119",
        },
      }),
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <ChakraProvider theme={theme}>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme()}
        coolMode
      >
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </ChakraProvider>
);
