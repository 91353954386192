import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  useMemo,
  useTransition,
} from "react";
import logo from "./assets/galactic-logo-branding4.svg";

import "intro.js/introjs.css";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Box,
  MenuButton,
  Menu,
  MenuItem,
  MenuList,
  TableContainer,
  Table,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Spinner,
  Link,
  useToast,
  Badge,
  Tooltip,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  ButtonGroup,
} from "@chakra-ui/react";
import {
  MdAccountBalanceWallet,
  MdEmail,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdHelpCenter,
} from "react-icons/md";
import { SiCheckmarx } from "react-icons/si";
import { GrWorkshop } from "react-icons/gr";
import { BsLink45Deg } from "react-icons/bs";
import abi from "./abi/GalacticAbi.json";
import { ethers } from "ethers";
import {
  browserName,
  browserVersion,
} from "react-device-detect";
import MetaMask from "./assets/metamask.svg";
import {
  useEffect,
  useState,
} from "react";
import { ImSpinner8 } from "react-icons/im";
import {
  useAccount,
  useSigner,
} from "wagmi";
import axios from "axios";
import {
  useNetwork,
  useBalance,
} from "wagmi";
import { FaArrowRight } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import {
  HiBadgeCheck,
  HiOutlineMail,
} from "react-icons/hi";
import Welcome from "./components/Welcome";
import Footer from "./components/Footer";

function App() {
  const { address } = useAccount();
  const { data, isError, isLoading } =
    useBalance({
      addressOrName: address,
    });
  const { data: signer } = useSigner();

  var cookie = getCookie(
    "cryptoclaims"
  );

  const [step, setStep] = useState(1);

  const NextStep = () => {
    setStep(step + 1);
  };
  const PreviousStep = () => {
    setStep(step - 1);
  };

  const Continue = (e) => {
    e.preventDefault();
    NextStep();
  };
  const Previous = (e) => {
    e.preventDefault();
    PreviousStep();
  };

  const toast = useToast();
  const { chain } = useNetwork();
  const network = {
    fuji: {
      chainId: `0x${Number(
        process.env
          .REACT_APP_AVALANCHE_CHAIN_ID
      ).toString(16)}`,
      chainName:
        process.env
          .REACT_APP_AVALANCHE_CHAIN_NAME,
      nativeCurrency: {
        name: process.env
          .REACT_APP_AVALANCHE_CHAIN_SYMBOL,
        symbol:
          process.env
            .REACT_APP_AVALANCHE_CHAIN_SYMBOL,
        decimals: 18,
      },
      rpcUrls: [
        `${process.env.REACT_APP_AVALANCHE_RPC_LINK}`,
      ],
      blockExplorerUrls: [
        `${process.env.REACT_APP_AVALANCHE_BLOCK_EXPLORER_LINK}`,
      ],
    },
  };

  const changeNetwork = async ({
    networkName,
    setError,
  }) => {
    try {
      if (!window?.ethereum)
        throw new Error(
          "No crypto wallet found"
        );
      await window?.ethereum?.request({
        method:
          "wallet_addEthereumChain",
        params: [
          {
            ...network[networkName],
          },
        ],
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const [error, setError] = useState();

  const handleNetworkSwitch = async (
    networkName
  ) => {
    setError();
    await changeNetwork({
      networkName,
      setError,
    });
  };

  const networkChanged = (chainId) => {
    if (
      chainId ===
      `${process.env.REACT_APP_AVALANCHE_CHAIN_ID_STRING}`
    ) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window?.ethereum?.on(
      "chainChanged",
      networkChanged
    );

    return () => {
      window?.ethereum?.removeListener(
        "chainChanged",
        networkChanged
      );
    };
  }, []);

  const [isPending, startTransaction] =
    useTransition();

  const [
    inputAddress,
    setInputAddress,
  ] = useState("");
  const [loading, setLoading] =
    useState(false);
  const [argloading, setArgLoading] =
    useState(false);

  const provider =
    window?.ethereum != undefined
      ? new ethers.providers.Web3Provider(
          window?.ethereum
        )
      : ethers.providers.getDefaultProvider();
  const Signer =
    window?.ethereum != undefined
      ? provider.getSigner()
      : signer;
  const Contract = new ethers.Contract(
    `${process.env.REACT_APP_SNOWTRACE_ADDRESS}`,
    abi,
    Signer
  );

  const [networks, setNetworks] =
    useState([]);
  const [purposes, setPurposes] =
    useState([]);
  const [
    transactionhash,
    setTransactionhash,
  ] = useState([]);

  const [decimal, setDecimal] =
    useState(0);

  const [convid, setConvId] =
    useState(null);

  const [noData, setNoData] =
    useState(false);

  useEffect(() => {
    window?.ethereum?.on(
      "accountsChanged",
      (account) => {
        toast({
          title: "Account Changed",
          description: `${account[0]}`,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    );
    const getNetworkAndPurposes =
      () => {
        Contract.getNetworks().then(
          (network) => {
            setNetworks(network);
          }
        );

        Contract.getPurposes().then(
          (purpose) => {
            setPurposes(purpose);
          }
        );
      };

    getNetworkAndPurposes();

    const zeros = `0x000000000000000000000000${address
      ?.toLowerCase()
      .replace("0x", "")}`;

    const baseUrl = `https://${process.env.REACT_APP_SNOWTRACE_URL}/api?module=logs&action=getLogs&address=${process.env.REACT_APP_SNOWTRACE_ADDRESS}&topic1=${zeros}&apikey=${process.env.REACT_APP_SNOWTRACE_API_KEY}`;
    const getSnowTracedata =
      async () => {
        await axios
          .get(`${baseUrl}`)
          .then((res) => {
            const data = res.data;

            if (
              data?.message ===
              "No records found"
            ) {
              setNoData(true);
            }
            startTransaction(
              async () => {
                const txh = [];

                for (
                  let i = 0;
                  i <=
                  data?.result?.length -
                    1;
                  i++
                ) {
                  txh.push([
                    data?.result[i]
                      ?.transactionHash,
                    data?.result[i]
                      ?.timeStamp,
                  ]);
                }
                setTransactionhash(txh);
              }
            );
          });
      };

    getSnowTracedata();
  }, [address]);

  const [args, setArgs] = useState([]);

  const checkBalance = () => {
    if (Number(data?.formatted) <= 0) {
      toast({
        title: "Insufficient Funds",
        description: `Your Current Balance ${data?.formatted} AVAX`,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "top",
      });

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  useEffect(() => {
    setArgLoading(true);
    startTransaction(async () => {
      const Args = [];
      let idx = null;
      for (
        let i = 0;
        i < transactionhash?.length;
        i++
      ) {
        let receipt =
          await provider.getTransactionReceipt(
            transactionhash[i][0]
          );

        let abi = [
          "event NonceGenerated(address indexed moonAddress, uint indexed purposeIdx, uint randomFee, string earthAddress)",
        ];
        let iface =
          new ethers.utils.Interface(
            abi
          );

        let log = await iface.parseLog(
          receipt.logs[0]
        );

        await Contract.getNetworkForPurposeIdx(
          Number(
            log?.args?.purposeIdx?.toString()
          )
        ).then(async (purposeidx) => {
          await Contract.getDecimal(
            purposeidx
          ).then((decimal) => {
            Args.push([
              log.args,
              transactionhash[i][0],
              transactionhash[i][1],
              purposeidx,
              decimal,
            ]);
          });
        });

        if (log) {
          setArgLoading(false);
        }
      }

      setArgs(Args);
    });
  }, [transactionhash]);

  /*const getLogs = () => {
    startTransaction(async () => {
      for (
        let i = 0;
        i < transactionhash?.length;
        i++
      ) {
        let receipt =
          await provider.getTransactionReceipt(
            transactionhash[i]
          );

        let abi = [
          "event NonceGenerated(address indexed moonAddress, uint indexed purposeIdx, uint randomFee, string earthAddress)",
        ];
        let iface =
          new ethers.utils.Interface(
            abi
          );

        let log = await iface.parseLog(
          receipt.logs[0]
        );

        console.log(log);
      }
    });
  };*/

  const randomSeed = Math.floor(
    Math.random() * 1000000000 + 1
  );

  const [txforModal, setTxForModal] =
    useState("");

  const [isOpen, setIsOpen] =
    useState(false);

  const [modalArgs, setModalArgs] =
    useState([]);

  const getGalactic = async () => {
    setLoading(true);
    try {
      const tx =
        await Contract.generateNonce(
          inputAddress,
          Number(convid),
          SelectPurposeidx,
          randomSeed
        );

      const tx_receipt =
        await tx.wait();

      let Abi = [
        "event NonceGenerated(address indexed moonAddress, uint indexed purposeIdx, uint randomFee, string earthAddress)",
      ];

      setTxForModal(
        tx_receipt?.transactionHash
      );

      let iface =
        new ethers.utils.Interface(Abi);
      let log = await iface.parseLog(
        tx_receipt.logs[0]
      );

      if (log) {
        setLoading(false);
        setIsOpen(true);
        setModalArgs(log?.args);
        getNetwork(
          log?.args?.purposeIdx
        );
        setIsOpenConfirmation(false);
      }
    } catch (error) {
      setIsOpenConfirmation(false);
      setLoading(false);
      console.error(error);
    }
  };

  const [
    SelectNetwork,
    setSelectedNetwork,
  ] = useState("Select Claim Network");
  const [
    SelectPurpose,
    setSelectedPurpose,
  ] = useState("Select Campaign");

  const [
    SelectNetworkidx,
    setSelectedNetworkIdx,
  ] = useState(0);
  const [
    SelectPurposeidx,
    setSelectedPurposeIdx,
  ] = useState(0);

  const selectNetwork = (e) => {
    let network =
      e.currentTarget.attributes[
        "data-network"
      ].value;
    let networkidx =
      e.currentTarget.attributes[
        "data-network-idx"
      ].value;

    setSelectedNetwork(network);
    setSelectedNetworkIdx(networkidx);
  };

  const [
    networksVisible,
    setNetworksVisible,
  ] = useState(true);

  const selectPurpose = (e) => {
    let purpose =
      e.currentTarget.attributes[
        "data-purpose"
      ].value;
    let purposeidx =
      e.currentTarget.attributes[
        "data-purpose-idx"
      ].value;

    Contract.getDecimal(
      purposeidx
    ).then((decimal) => {
      setDecimal(
        Number(decimal.toString())
      );
    });

    Contract.getNetworkForPurposeIdx(
      purposeidx
    ).then((id) => {
      const convId = Number(id);
      setConvId(convId);
      setNetworksVisible(false);
    });

    setSelectedPurpose(purpose);
    setSelectedPurposeIdx(purposeidx);
  };

  const [hideTable, setHideTable] =
    useState(false);

  const [dataToggle, setDataToggle] =
    useState("");

  const Showhide = (e) => {
    let dataToggleId =
      e.currentTarget.attributes[
        "data-toggle"
      ].value;

    setDataToggle(dataToggleId);
  };

  const [copySuccess, setCopySuccess] =
    useState(false);

  const copyContent = async (e) => {
    let datacryptoclaim =
      e.currentTarget.attributes[
        "data-cryptoclaims"
      ].value;

    try {
      await navigator.clipboard.writeText(
        datacryptoclaim
      );
      setCopySuccess(true);

      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    } catch (err) {
      console.error(
        "Failed to copy!",
        err
      );
    }
  };

  const [email, setEmail] =
    useState("");

  const [errorEmail, setErrorEmail] =
    useState("");

  const ValidateEmail = () => {
    var mailformat =
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email?.match(mailformat)) {
      setErrorEmail("");
      postEmail();
    } else {
      setErrorEmail(
        "You have entered an invalid email address!"
      );
    }
  };

  const postEmail = () => {
    const payload = {
      earth_address: `${modalArgs?.earthAddress}`,
      moon_address: `${modalArgs?.moonAddress}`,
      purpose_idx: `${Number(
        modalArgs?.purposeIdx?.toString()
      )}`,
      random_fee: `${Number(
        modalArgs?.randomFee?.toString()
      )}`,
      email: `${email}`,
    };

    const res = axios.post(
      `${process.env.REACT_APP_GALACTIC_API_URL}/link`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_GALACTIC_API_AUTH}`,
        },
      }
    );

    res.then((res) => {
      if (res.status === 200) {
        toast({
          title: "Email Submitted",
          description:
            "Thanks! We've recorded your claim details, and your email will be shared with the claim provider.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    });
  };

  function setCookie(
    name,
    value,
    days
  ) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(
        date.getTime() +
          days * 24 * 60 * 60 * 1000
      );
      expires =
        "; expires=" +
        date.toUTCString();
    }
    document.cookie =
      name +
      "=" +
      (value || "") +
      expires +
      "; path=/";
  }
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (
      var i = 0;
      i < ca.length;
      i++
    ) {
      var c = ca[i];
      while (c.charAt(0) == " ")
        c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0)
        return c.substring(
          nameEQ.length,
          c.length
        );
    }
    return null;
  }

  function eraseCookie(name) {
    document.cookie =
      name +
      "=; Path=/; Expires=Thu, 01 Jan 2023 00:00:01 GMT;";
  }

  const Intro = () => {
    eraseCookie("cryptoclaims");
    window.location.reload();
    /*.onbeforechange(() => {
        if (intro.currentStep() === 1) {
          intro.onchange((e) => {
            const purposes = JSON.parse(
              localStorage.getItem(
                "purposes"
              )
            );
            setSelectedPurpose(
              purposes[0]
            );
            setSelectedPurposeIdx(
              purposes.indexOf(
                purposes[0]
              )
            );
            Contract.getNetworkForPurposeIdx(
              SelectPurposeidx
            ).then((id) => {
              const convId = Number(id);
              setConvId(convId);
              setNetworksVisible(false);
            });
          });
        }
      })*/
  };

  const [
    checkedUSResident,
    setCheckedUSResident,
  ] = useState(false);

  localStorage.setItem(
    "purposes",
    JSON.stringify(purposes)
  );

  const [isHelpOpen, setIsHelpOpen] =
    useState(false);
  const [Network, setNetwork] =
    useState("wait...");

  const getNetwork = async (
    purposeIdx
  ) => {
    await Contract.getNetworkForPurposeIdx(
      Number(purposeIdx?.toString())
    ).then((id) => {
      const convId = Number(id);
      Contract.getNetwork(convId).then(
        (network) => {
          setNetwork(network);
        }
      );
    });
  };

  const [
    isOpenConfirmation,
    setIsOpenConfirmation,
  ] = useState(false);

  return (
    <Box className="bg-black w-full h-screen overflow-y-hidden overflow-x-hidden">
      <Modal
        onClose={() => {
          setIsOpenConfirmation(false);
        }}
        isOpen={isOpenConfirmation}
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent className="p-3">
          <ModalCloseButton
            color="white"
            onClick={() =>
              setIsOpenConfirmation(
                false
              )
            }
          />
          <ModalBody p="6">
            <div>
              <div className="flex items-center space-x-4">
                <SiCheckmarx className="text-green-500 text-3xl" />
                <Text className="text-3xl text-white font-extrabold">
                  Please Confirm Details
                </Text>
              </div>
              <div className="mt-8 mb-8 space-y-4">
                <div>
                  <Text className="text-xl text-galactic_blue font-bold">
                    Campaign :
                  </Text>
                  <div className="bg-black/30 mt-3 p-3 rounded-lg">
                    <Text className="text-lg text-galactic_purple font-semibold">
                      {SelectPurpose}
                    </Text>
                  </div>
                </div>
                <div>
                  <Text className="text-xl text-galactic_blue font-bold">
                    Claim Network :
                  </Text>
                  <div className="bg-black/30 mt-3 p-3 rounded-lg">
                    <Text className="text-lg text-galactic_purple font-semibold">
                      {networks[convid]}
                    </Text>
                  </div>
                </div>
                <div>
                  <Text className="text-xl text-galactic_blue font-bold">
                    Claim Network
                    Address :
                  </Text>
                  <div className="bg-black/30 mt-3 p-3 rounded-lg">
                    <Text className="text-lg text-galactic_purple font-semibold">
                      {inputAddress}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  colorScheme="whatsapp"
                  size="lg"
                  fontSize="xl"
                  isLoading={loading}
                  loadingText="Submitting"
                  leftIcon={
                    loading ? (
                      <ImSpinner8 />
                    ) : (
                      <BsLink45Deg />
                    )
                  }
                  onClick={() => {
                    getGalactic();
                    checkBalance();
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="flex items-center justify-between p-3 xl:px-8">
        <div className="">
          <img
            src={logo}
            className=""
          />
        </div>
        <div>
          {chain?.id ===
            Number(
              process.env
                .REACT_APP_AVALANCHE_CHAIN_ID
            ) || !address ? (
            <Box className="flex items-center space-x-4">
              <Box className="w-auto">
                {browserName ===
                  "Firefox" &&
                window?.ethereum ===
                  undefined ? (
                  <div
                    className=" flex items-center justify-center space-x-3 px-3 py-2 bg-galactic_blue rounded-lg mb-4 hover:cursor-pointer transition-all duration-200 ease-linear hover:bg-opacity-80"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_METAMASK_INSTALLATION_LINK}`
                      )
                    }
                  >
                    <h1 className="text-white text-lg font-bold">
                      Install MetaMask
                    </h1>
                    <img
                      src={MetaMask}
                      className="w-5 h-5"
                    />
                  </div>
                ) : (
                  <ConnectButton />
                )}
              </Box>
              {address && (
                <Tooltip
                  label="See how it works"
                  hasArrow
                  bg="#60c6fa"
                  color="#000000"
                >
                  <span className="p-2">
                    <GrWorkshop
                      className="bg-galactic_blue text-2xl hover:cursor-pointer"
                      onClick={Intro}
                    />
                  </span>
                </Tooltip>
              )}

              {address && (
                <Tooltip
                  label="help"
                  hasArrow
                  bg="#a253f7"
                >
                  <span className="p-2">
                    <MdHelpCenter
                      className="text-galactic_purple text-3xl hover:cursor-pointer"
                      onClick={() =>
                        setIsHelpOpen(
                          true
                        )
                      }
                    />
                  </span>
                </Tooltip>
              )}
            </Box>
          ) : (
            <Button
              leftIcon={
                <MdAccountBalanceWallet />
              }
              colorScheme="red"
              variant="solid"
              onClick={() =>
                handleNetworkSwitch(
                  "fuji"
                )
              }
            >
              WRONG NETWORK
            </Button>
          )}
        </div>
      </div>
      {address ? (
        <>
          <Modal
            onClose={() => {
              setIsOpen(false);
              window.location.reload();
            }}
            isOpen={isOpen}
            isCentered
            size="5xl"
          >
            <ModalOverlay />
            <ModalContent className="p-3">
              <ModalCloseButton
                color="white"
                onClick={() => {
                  setIsOpen(false);
                  window.location.reload();
                }}
              />
              <ModalBody p="6">
                <Box className="flex flex-col space-y-2 flex-wrap overflow-x-auto">
                  <Popover
                    isOpen={
                      step === 5 &&
                      !cookie
                    }
                    placement="bottom"
                  >
                    <PopoverTrigger>
                      <Box
                        className={`${
                          step === 5 &&
                          !cookie
                            ? "border-white"
                            : "border-[#111119]"
                        } border-2 rounded-lg p-2 `}
                      >
                        <Text className="text-sm font-bold text-gray-400 mb-2">
                          SUMMARY :
                        </Text>
                        <Box className="bg-gradient-to-r from-galactic_blue/40 to-galactic_purple/40 p-3 rounded-md w-full flex flex-wrap">
                          <Box>
                            {" "}
                            <Box
                              className="flex items-center mt-2 bg-gray-300 p-2 rounded-md"
                              color="blue.500"
                            >
                              <Link
                                href={`https://testnet.snowtrace.io/tx/${txforModal}`}
                                isExternal
                              >
                                {`https://testnet.snowtrace.io/tx/${txforModal}`}{" "}
                              </Link>
                              <FiExternalLink className="ml-1" />
                            </Box>
                          </Box>

                          <p className="whitespace-normal text-center leading-6 bg-gray-300 rounded-md mt-3 p-2">
                            The request
                            to link your{" "}
                            <span className="font-bold text-gray-800">
                              {Network}
                            </span>{" "}
                            address{" "}
                            <Tooltip
                              label={
                                copySuccess
                                  ? "Copied!"
                                  : "Copy"
                              }
                              hasArrow
                            >
                              <span
                                onClick={(
                                  e
                                ) => {
                                  copyContent(
                                    e
                                  );
                                }}
                                data-cryptoclaims={`${modalArgs?.earthAddress}`}
                                className="font-bold text-gray-800 underline hover:cursor-pointer"
                              >
                                (
                                {`${modalArgs?.earthAddress}`}
                                )
                              </span>
                            </Tooltip>{" "}
                            to{" "}
                            <span className="font-bold text-gray-800">{`${chain.name}`}</span>{" "}
                            address{" "}
                            <span className="font-bold text-gray-800">
                              (
                              {`${modalArgs?.moonAddress}`}
                              )
                            </span>{" "}
                            has been
                            submitted.
                          </p>
                        </Box>
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverHeader fontWeight="semibold">
                        5. Receipt
                      </PopoverHeader>
                      <PopoverArrow />
                      <PopoverCloseButton
                        onClick={() =>
                          setStep(0)
                        }
                      />
                      <PopoverBody>
                        Once the
                        transaction is
                        completed. You
                        will get an
                        acknowledgement.
                        It is advised to
                        take screenshot
                        of this receipt.
                        <Box className="mt-3">
                          <Text className="text-xs font-semibold text-slate-900">
                            {step} / 7
                          </Text>
                        </Box>
                      </PopoverBody>
                      <PopoverFooter
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <ButtonGroup size="sm">
                          <Button
                            colorScheme="whatsapp"
                            onClick={
                              Continue
                            }
                          >
                            Next
                          </Button>
                        </ButtonGroup>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                  <Box className="bg-black/30 rounded-md p-3 space-y-2">
                    <Box className="flex items-center">
                      <Text className="text-sm font-bold text-gray-400">
                        FROM ADDRESS :
                      </Text>
                      <Text className="ml-2 text-gray-400">
                        {
                          modalArgs?.earthAddress
                        }
                      </Text>
                    </Box>
                    <Box className="flex items-center">
                      <Text className="text-sm font-bold text-gray-400">
                        TO ADDRESS :
                      </Text>
                      <Text className="ml-2 text-gray-400">
                        {
                          modalArgs?.moonAddress
                        }
                      </Text>
                    </Box>
                    <Box className="flex items-center ">
                      <Text className="text-sm font-bold text-gray-400">
                        PURPOSE INDEX :
                      </Text>
                      <Text className="ml-2 text-gray-400">
                        {modalArgs?.purposeIdx?.toString()}
                      </Text>
                    </Box>

                    <Box className="flex items-center">
                      <Text className="text-sm font-bold text-gray-400">
                        RANDOM FEE :
                      </Text>
                      <Text
                        className="ml-2 text-gray-400"
                        id="#transaction-randomfee"
                      >
                        {modalArgs?.randomFee?.toString()}
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Text className="text-sm font-bold text-gray-400 mb-2 mt-2">
                  INSTRUCTIONS :
                </Text>
                <Popover
                  isOpen={
                    step === 6 &&
                    !cookie
                  }
                  placement="bottom"
                >
                  <PopoverTrigger>
                    <Box
                      className={`${
                        step === 6 &&
                        !cookie
                          ? "border-white"
                          : "border-[#111119]"
                      } border-2 rounded-lg p-2 `}
                    >
                      <Box className="bg-gradient-to-r from-galactic_blue/40 to-galactic_purple/40 p-3 rounded-md">
                        <Box className="bg-white rounded-md p-2">
                          <p className="text-center">
                            As a next
                            step for the
                            campaign{" "}
                            <span className="font-bold text-galactic_blue">{`${
                              purposes[
                                Number(
                                  modalArgs?.purposeIdx?.toString()
                                )
                              ]
                            }`}</span>{" "}
                            , please
                            send{" "}
                            <Tooltip
                              label={
                                copySuccess
                                  ? "Copied!"
                                  : "Copy"
                              }
                              hasArrow
                            >
                              <span
                                onClick={(
                                  e
                                ) => {
                                  copyContent(
                                    e
                                  );
                                }}
                                data-cryptoclaims={`${
                                  Number(
                                    modalArgs?.randomFee?.toString()
                                  ) /
                                  10 **
                                    decimal
                                }`}
                                className="font-bold text-galactic_blue underline hover:cursor-pointer"
                              >{`${
                                Number(
                                  modalArgs?.randomFee?.toString()
                                ) /
                                10 **
                                  decimal
                              }`}</span>
                            </Tooltip>{" "}
                            <span className="">
                              {Network}
                            </span>{" "}
                            from address{" "}
                            {""}
                            <Tooltip
                              label={
                                copySuccess
                                  ? "Copied!"
                                  : "Copy"
                              }
                              hasArrow
                            >
                              <span
                                onClick={(
                                  e
                                ) => {
                                  copyContent(
                                    e
                                  );
                                }}
                                data-cryptoclaims={`${modalArgs?.earthAddress}`}
                                className="font-bold text-galactic_blue underline hover:cursor-pointer"
                              >
                                (
                                {`${modalArgs?.earthAddress}`}
                                )
                              </span>
                            </Tooltip>{" "}
                            to the
                            address{" "}
                            <Tooltip
                              label={
                                copySuccess
                                  ? "Copied!"
                                  : "Copy"
                              }
                              hasArrow
                            >
                              <span
                                onClick={(
                                  e
                                ) => {
                                  copyContent(
                                    e
                                  );
                                }}
                                data-cryptoclaims={
                                  process
                                    .env[
                                    `REACT_APP_PURPOSE_${purposes.indexOf(
                                      purposes[
                                        Number(
                                          modalArgs?.purposeIdx?.toString()
                                        )
                                      ]
                                    )}_ADDRESS`
                                  ]
                                }
                                className="font-bold text-galactic_blue underline hover:cursor-pointer"
                              >
                                {
                                  process
                                    .env[
                                    `REACT_APP_PURPOSE_${purposes.indexOf(
                                      purposes[
                                        Number(
                                          modalArgs?.purposeIdx?.toString()
                                        )
                                      ]
                                    )}_ADDRESS`
                                  ]
                                }
                              </span>
                            </Tooltip>
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">
                      6. Send
                      verification
                      amount on claim
                      network
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton
                      onClick={() =>
                        setStep(0)
                      }
                    />
                    <PopoverBody>
                      To complete the
                      verification
                      process, you need
                      to send the
                      specific tiny
                      amount mentioned
                      in the receipt
                      from your claim
                      network wallet
                      address to
                      campaign owner's
                      wallet address on
                      the same network.
                      The address is
                      mentioned in the
                      instructions. Our
                      team will do the
                      verification and
                      inform the
                      campaign owners.
                      <Box className="mt-3">
                        <Text className="text-xs font-semibold text-slate-900">
                          {step} / 7
                        </Text>
                      </Box>
                    </PopoverBody>
                    <PopoverFooter
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <ButtonGroup size="sm">
                        <Button
                          variant="outline"
                          onClick={
                            Previous
                          }
                        >
                          Back
                        </Button>
                        <Button
                          colorScheme="whatsapp"
                          onClick={
                            Continue
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
                <Box className="flex items-center space-x-2 justify-center mt-3 mb-3 p-3 bg-black/30 rounded">
                  <Text className="text-lg text-gray-300">
                    If you'd like to be
                    notified of the
                    updates, please
                    share your email
                  </Text>
                  <MdNotificationsActive className="text-4xl xl:text-2xl md:text-2xl text-yellow-500" />
                </Box>
                <Popover
                  isOpen={
                    step === 7 &&
                    !cookie
                  }
                  placement="bottom"
                >
                  <PopoverTrigger>
                    <Box
                      className={`${
                        step === 7 &&
                        !cookie
                          ? "border-white"
                          : "border-[#111119]"
                      } border-2 rounded-lg p-2 `}
                    >
                      <Box className="flex items-center space-x-2 ">
                        <InputGroup size="lg">
                          <InputLeftElement
                            pointerEvents="none"
                            children={
                              <HiOutlineMail className="text-gray-600 focus:text-white" />
                            }
                          />
                          <Input
                            focusBorderColor="white"
                            variant="filled"
                            placeholder="Email"
                            className="mx-auto placeholder:text-gray-800 focus:placeholder:text-white focus:text-white text-gray-800"
                            value={
                              email
                            }
                            onChange={(
                              e
                            ) => {
                              setEmail(
                                e.target
                                  .value
                              );
                              setErrorEmail(
                                ""
                              );
                            }}
                            type="email"
                          />
                        </InputGroup>
                        <Button
                          leftIcon={
                            <MdEmail />
                          }
                          colorScheme="whatsapp"
                          variant="solid"
                          size="lg"
                          onClick={() => {
                            ValidateEmail();
                          }}
                        >
                          Email
                        </Button>
                      </Box>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">
                      7. Finished 😊
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton
                      onClick={() =>
                        setStep(0)
                      }
                    />
                    <PopoverBody>
                      After successful
                      verification, the
                      campaign operator
                      will take
                      necessary actions
                      to fulfill the
                      claim. Optionally,
                      you can provide
                      your email
                      address, we'll
                      share that with
                      the campaign
                      operators so that
                      they can keep you
                      informed. You can
                      see the claim
                      requests you have
                      made so far by
                      logging into the
                      site with the same
                      wallet.
                      <Box className="mt-3">
                        <Text className="text-xs font-semibold text-slate-900">
                          {step} / 7
                        </Text>
                      </Box>
                    </PopoverBody>
                    <PopoverFooter
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <ButtonGroup size="sm">
                        <Button
                          variant="outline"
                          onClick={
                            Previous
                          }
                        >
                          Back
                        </Button>
                        <Button
                          colorScheme="whatsapp"
                          onClick={() => {
                            setCookie(
                              "cryptoclaims",
                              Date.now(),
                              7
                            );
                            setStep(1);
                          }}
                        >
                          Finished
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
                {errorEmail ===
                  "You have entered an invalid email address!" && (
                  <Text className="text-red-500 ml-1 mt-1">
                    {errorEmail}
                  </Text>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>

          <Modal
            onClose={() => {
              setIsHelpOpen(false);
            }}
            isOpen={isHelpOpen}
            isCentered
            size="5xl"
          >
            <ModalOverlay />
            <ModalContent className="p-3">
              <ModalCloseButton
                color="white"
                onClick={() =>
                  setIsHelpOpen(false)
                }
              />
              <ModalBody p="6">
                <Box className="flex items-center flex-col p-2">
                  <h1 className="text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-8xl tracking-tight text-center">
                    <b className="text-white">
                      Welcome to
                    </b>{" "}
                    <b className="text-galactic_blue">
                      Crypto
                    </b>
                    <b className="text-galactic_purple">
                      Claims
                    </b>
                  </h1>
                  <p className="mt-10 text-lg text-slate-500 text-center max-w-3xl mx-auto p-3">
                    If you have received
                    notification of an{" "}
                    <span className="text-galactic_blue">
                      airdrop
                    </span>{" "}
                    or any other{" "}
                    <span className="text-galactic_blue">
                      campaign
                    </span>{" "}
                    from a{" "}
                    <span className="text-galactic_purple">
                      crypto project
                    </span>{" "}
                    you can register for
                    the campaign here.
                    You can optionally
                    choose to enter your
                    email address after
                    registering, in
                    which case, you will
                    automatically be
                    notified of future
                    campaigns to receive
                    crypto bonuses and
                    airdrops. <br />{" "}
                  </p>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>

          <div className="flex items-center justify-center flex-col p-2 mt-6">
            <Box className="flex items-center justify-around w-full xl:w-1/2 xl:mx-auto md:w-2/3 md:mx-auto px-3 space-x-2 z-[50]">
              <Menu>
                <Popover
                  isOpen={
                    step === 1 &&
                    !cookie
                  }
                  placement="left"
                >
                  <PopoverTrigger>
                    <Box
                      className={`${
                        step === 1 &&
                        !cookie
                          ? "border-white p-2"
                          : "border-black p-0"
                      } border-2 rounded-lg w-full`}
                    >
                      <Box className="">
                        <MenuButton
                          as={Button}
                          rightIcon={
                            <MdKeyboardArrowDown />
                          }
                          className=" text-white hover:text-black w-full"
                          bg="gray.700"
                        >
                          {
                            SelectPurpose
                          }
                        </MenuButton>
                      </Box>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">
                      1. Select Campaign
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton
                      onClick={() =>
                        setStep(0)
                      }
                    />
                    <PopoverBody>
                      There may be
                      multiple campaigns
                      taking place at a
                      time, select the
                      campaign of your
                      interest.
                      <Box className="mt-3">
                        <Text className="text-xs font-semibold text-slate-900">
                          {step} / 7
                        </Text>
                      </Box>
                    </PopoverBody>
                    <PopoverFooter
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <ButtonGroup size="sm">
                        <Button
                          colorScheme="whatsapp"
                          onClick={
                            Continue
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
                <MenuList>
                  {purposes?.map(
                    (purpose, i) => (
                      <MenuItem
                        data-purpose={
                          purpose
                        }
                        data-purpose-idx={
                          i
                        }
                        minH="48px"
                        key={i}
                        onClick={
                          selectPurpose
                        }
                      >
                        <span>
                          {purpose}
                        </span>
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </Menu>
              <Menu>
                <Popover
                  isOpen={
                    step === 2 &&
                    !cookie
                  }
                  placement="right"
                >
                  <PopoverTrigger>
                    <Box
                      className={`${
                        step === 2 &&
                        !cookie
                          ? "border-white p-2"
                          : "border-black p-0"
                      } border-2 rounded-lg w-full`}
                    >
                      {" "}
                      <MenuButton
                        as={Button}
                        rightIcon={
                          <MdKeyboardArrowDown />
                        }
                        disabled={
                          networksVisible
                        }
                        className=" text-white hover:text-black w-full"
                        bg="gray.700"
                      >
                        {convid === null
                          ? SelectNetwork
                          : networks[
                              convid
                            ]}
                      </MenuButton>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">
                      2. Select Claim
                      Network
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton
                      onClick={() =>
                        setStep(0)
                      }
                    />
                    <PopoverBody>
                      Select the network
                      from which you are
                      claiming for. If
                      unclear about
                      this, please ask
                      the campaign
                      operator.
                      <Box className="mt-3">
                        <Text className="text-xs font-semibold text-slate-900">
                          {step} / 7
                        </Text>
                      </Box>
                    </PopoverBody>
                    <PopoverFooter
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <ButtonGroup size="sm">
                        <Button
                          variant="outline"
                          onClick={
                            Previous
                          }
                        >
                          Back
                        </Button>
                        <Button
                          colorScheme="whatsapp"
                          onClick={
                            Continue
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
                <MenuList>
                  <MenuItem
                    data-network={
                      networks[convid]
                    }
                    data-network-idx={
                      convid
                    }
                    minH="48px"
                    onClick={
                      selectNetwork
                    }
                  >
                    <span>
                      {networks[convid]}
                    </span>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <div className="flex items-center p-3 w-full xl:w-1/2 xl:mx-auto md:w-2/3 md:mx-auto space-x-2">
              <Popover
                isOpen={
                  step === 3 && !cookie
                }
                placement="bottom"
              >
                <PopoverTrigger>
                  <Box
                    className={`${
                      step === 3 &&
                      !cookie
                        ? "border-white p-2"
                        : "border-black p-0"
                    } border-2 rounded-lg w-full`}
                  >
                    <InputGroup size="lg">
                      <InputLeftElement
                        pointerEvents="none"
                        children={
                          <MdAccountBalanceWallet className="text-gray-500 focus:text-white" />
                        }
                      />
                      <Input
                        focusBorderColor="white"
                        variant="filled"
                        placeholder="Enter your wallet address on the claim network"
                        className="mx-auto placeholder:text-gray-800 focus:placeholder:text-white focus:text-white text-gray-800"
                        value={
                          inputAddress
                        }
                        onChange={(e) =>
                          setInputAddress(
                            e.target
                              .value
                          )
                        }
                      />
                    </InputGroup>
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverHeader fontWeight="semibold">
                    3. Enter your wallet
                    address
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverCloseButton
                    onClick={() =>
                      setStep(0)
                    }
                  />
                  <PopoverBody>
                    Enter your wallet
                    address in the Claim
                    network selected in
                    last step, so if you
                    are claiming for a
                    campaign related to
                    BTC holders then
                    this would be your
                    BTC address.
                    <Box className="mt-3">
                      <Text className="text-xs font-semibold text-slate-900">
                        {step} / 7
                      </Text>
                    </Box>
                  </PopoverBody>
                  <PopoverFooter
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <ButtonGroup size="sm">
                      <Button
                        variant="outline"
                        onClick={
                          Previous
                        }
                      >
                        Back
                      </Button>
                      <Button
                        colorScheme="whatsapp"
                        onClick={
                          Continue
                        }
                      >
                        Next
                      </Button>
                    </ButtonGroup>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>

              <Popover
                isOpen={
                  step === 4 && !cookie
                }
                placement="bottom"
              >
                <PopoverTrigger>
                  <div
                    className={`${
                      step === 4 &&
                      !cookie
                        ? "border-white p-2"
                        : "border-black p-0"
                    } border-2 rounded-lg `}
                  >
                    <Button
                      size="lg"
                      colorScheme="whatsapp"
                      variant="solid"
                      onClick={() =>
                        setIsOpenConfirmation(
                          true
                        )
                      }
                      disabled={
                        inputAddress ===
                          "" ||
                        SelectPurpose ===
                          "Select Campaign" ||
                        checkedUSResident ===
                          false
                      }
                      isLoading={
                        loading
                      }
                      loadingText="submitting"
                      leftIcon={
                        loading && (
                          <ImSpinner8 />
                        )
                      }
                    >
                      Send Claim Request
                    </Button>
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverHeader fontWeight="semibold">
                    4. Send Claim
                    Request
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverCloseButton
                    onClick={() =>
                      setStep(0)
                    }
                  />
                  <PopoverBody>
                    Once you click on
                    "Send Claim
                    Request", your
                    wallet will prompt
                    you to submit a
                    transaction. This
                    transaction would
                    associate your
                    wallet addresses on
                    the claim network
                    and current network.
                    It will also
                    generate a random
                    small amount which
                    you need to send to
                    verify that you own
                    the claim address.
                    <Box className="mt-3">
                      <Text className="text-xs font-semibold text-slate-900">
                        {step} / 7
                      </Text>
                    </Box>
                  </PopoverBody>
                  <PopoverFooter
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <ButtonGroup size="sm">
                      <Button
                        variant="outline"
                        onClick={
                          Previous
                        }
                      >
                        Back
                      </Button>
                      <Button
                        colorScheme="whatsapp"
                        onClick={
                          Continue
                        }
                      >
                        Done
                      </Button>
                    </ButtonGroup>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            </div>
            <div className="w-[90%] md:w-1/2 xl:w-1/2 mx-auto text-slate-400 p-3">
              <Checkbox
                colorScheme="green"
                onChange={(e) => {
                  setCheckedUSResident(
                    e.target.checked
                  );
                }}
              >
                <Text className="text-center text-xs mt-3 xl:mt-0 md:mt-3">
                  I certify that I am
                  not a US resident.
                </Text>
              </Checkbox>
            </div>
          </div>

          {chain.id ===
            Number(
              process.env
                .REACT_APP_AVALANCHE_CHAIN_ID
            ) && (
            <Box className="no-scrollbar w-full xl:w-3/4 mx-auto overflow-y-scroll h-[50%] mt-5">
              {argloading ? (
                <Box className="w-full flex justify-center items-center h-full flex-col">
                  <Spinner
                    thickness="8px"
                    speed="0.95s"
                    emptyColor="#60c6fa"
                    color="#a253f7"
                    size="xl"
                  />
                  {noData && (
                    <Box className="flex flex-col items-center justify-center">
                      <Box className="flex items-center mt-3 animate-pulse text-2xl">
                        <Text className="text-gray-500 font-bold">
                          <span className="text-galactic_blue">
                            Crypto
                          </span>{" "}
                          <span className="text-galactic_purple">
                            Claims
                          </span>{" "}
                          No Data
                        </Text>
                      </Box>
                      <Text className="text-gray-700 text-sm">
                        Please Claim.
                      </Text>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  <Box className="w-full flex flex-col-reverse p-4">
                    {args?.map(
                      (arg, i) => (
                        <Box
                          key={i}
                          className="w-full p-4 border-4 mt-4 rounded-lg border-gray-700 selection:text-white selection:bg-slate-600"
                        >
                          <TableContainer>
                            <Table>
                              <Tbody>
                                <Tr>
                                  <Td>
                                    <Text className="text-xs font-bold text-white">
                                      SUMMARY
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Box className="bg-gradient-to-r from-galactic_blue/40 to-galactic_purple/40 p-3 rounded-md w-full">
                                      <Box className="flex items-center justify-between mb-5">
                                        {" "}
                                        <Box className="flex items-center space-x-3">
                                          {" "}
                                          <Badge colorScheme="gray">
                                            {new Date(
                                              arg[2] *
                                                1000
                                            ).toUTCString()}
                                          </Badge>
                                          <Tooltip
                                            label="claim request submitted successfully"
                                            hasArrow
                                          >
                                            <span>
                                              <HiBadgeCheck className="text-green-400 text-2xl hover:cursor-pointer" />
                                            </span>
                                          </Tooltip>
                                        </Box>
                                        <Box className="flex items-center justify-end">
                                          <Button
                                            data-toggle={
                                              arg[1]
                                            }
                                            onClick={(
                                              e
                                            ) => {
                                              Showhide(
                                                e
                                              );
                                              setHideTable(
                                                !hideTable
                                              );
                                            }}
                                          >
                                            <MdKeyboardArrowDown
                                              className={`text-xl transition-all duration-200 ease-in-out ${
                                                hideTable &&
                                                dataToggle ===
                                                  arg[1] &&
                                                "rotate-180 transition-all duration-200 ease-in-out"
                                              }`}
                                            />
                                          </Button>
                                        </Box>
                                      </Box>
                                      <Box className="flex items-center mt-2 bg-gray-100 p-2 rounded-md selection:text-white selection:bg-galactic_purple shadow-lg text-blue-500">
                                        <Link
                                          href={`https://testnet.snowtrace.io/tx/${arg[1]}`}
                                          isExternal
                                        >
                                          {`https://testnet.snowtrace.io/tx/${arg[1]}`}{" "}
                                        </Link>
                                        <FiExternalLink className="ml-1" />
                                      </Box>
                                      <p className="receiptparagraph selection:text-white selection:bg-galactic_blue text-black mt-2 bg-gray-100 p-2 rounded-md shadow-lg leading-6">
                                        The
                                        claim
                                        request
                                        for
                                        the
                                        campaign{" "}
                                        <span className="font-bold ">{`${
                                          purposes[
                                            Number(
                                              arg[0]?.purposeIdx?.toString()
                                            )
                                          ]
                                        }`}</span>{" "}
                                        has
                                        been
                                        submitted
                                        successfully.{" "}
                                        <br />
                                        As
                                        a
                                        next
                                        step,
                                        please
                                        send{" "}
                                        <Tooltip
                                          label={
                                            copySuccess
                                              ? "Copied!"
                                              : "Copy"
                                          }
                                          hasArrow
                                        >
                                          <span
                                            onClick={(
                                              e
                                            ) => {
                                              copyContent(
                                                e
                                              );
                                            }}
                                            data-cryptoclaims={`${
                                              Number(
                                                arg[0]?.randomFee?.toString()
                                              ) /
                                              10 **
                                                Number(
                                                  arg[4].toString()
                                                )
                                            }`}
                                            className="font-bold underline hover:cursor-pointer"
                                          >{`${
                                            Number(
                                              arg[0]?.randomFee?.toString()
                                            ) /
                                            10 **
                                              Number(
                                                arg[4].toString()
                                              )
                                          }`}</span>
                                        </Tooltip>{" "}
                                        <span className="font-bold ">{`${
                                          networks[
                                            Number(
                                              arg[3]?.toString()
                                            )
                                          ]
                                        }`}</span>{" "}
                                        from
                                        your
                                        linked{" "}
                                        <span className="font-bold ">{`${
                                          networks[
                                            Number(
                                              arg[3]?.toString()
                                            )
                                          ]
                                        }`}</span>{" "}
                                        address{" "}
                                        <Tooltip
                                          label={
                                            copySuccess
                                              ? "Copied!"
                                              : "Copy"
                                          }
                                          hasArrow
                                        >
                                          <span
                                            onClick={(
                                              e
                                            ) => {
                                              copyContent(
                                                e
                                              );
                                            }}
                                            data-cryptoclaims={`${arg[0]?.earthAddress}`}
                                            className="font-bold underline hover:cursor-pointer"
                                          >
                                            (
                                            {`${arg[0]?.earthAddress}`}

                                            )
                                          </span>
                                        </Tooltip>{" "}
                                        to
                                        the
                                        address{" "}
                                        <Tooltip
                                          label={
                                            copySuccess
                                              ? "Copied!"
                                              : "Copy"
                                          }
                                          hasArrow
                                        >
                                          <span
                                            onClick={(
                                              e
                                            ) => {
                                              copyContent(
                                                e
                                              );
                                            }}
                                            data-cryptoclaims={
                                              process
                                                .env[
                                                `REACT_APP_PURPOSE_${purposes.indexOf(
                                                  purposes[
                                                    Number(
                                                      arg[0]?.purposeIdx?.toString()
                                                    )
                                                  ]
                                                )}_ADDRESS`
                                              ]
                                            }
                                            className="font-bold underline hover:cursor-pointer"
                                          >
                                            {
                                              process
                                                .env[
                                                `REACT_APP_PURPOSE_${purposes.indexOf(
                                                  purposes[
                                                    Number(
                                                      arg[0]?.purposeIdx?.toString()
                                                    )
                                                  ]
                                                )}_ADDRESS`
                                              ]
                                            }
                                          </span>
                                        </Tooltip>{" "}
                                        .{" "}
                                        <br />
                                        Your
                                        claim
                                        will
                                        be
                                        processed
                                        on{" "}
                                        <span className="font-bold ">{`${chain.name}`}</span>{" "}
                                        network
                                        to
                                        your
                                        address{" "}
                                        <span className="font-bold ">{`${arg[0]?.moonAddress}`}</span>{" "}
                                        by
                                        the
                                        campaign
                                        owner.
                                        <br />
                                      </p>
                                    </Box>
                                  </Td>
                                </Tr>
                                <Tr
                                  className={`${
                                    hideTable &&
                                    dataToggle ===
                                      arg[1]
                                      ? "transition-all duration-300 ease-linear"
                                      : "hidden"
                                  }`}
                                >
                                  <Td>
                                    <Text className="text-xs font-bold text-white">
                                      EARTH
                                      ADDRESS
                                    </Text>
                                  </Td>
                                  <Td className="text-white">
                                    {
                                      arg[0]
                                        ?.earthAddress
                                    }
                                  </Td>
                                </Tr>
                                <Tr
                                  className={`${
                                    hideTable &&
                                    dataToggle ===
                                      arg[1]
                                      ? "transition-all duration-300 ease-linear"
                                      : "hidden"
                                  }`}
                                >
                                  <Td>
                                    <Text className="text-xs font-bold text-white">
                                      MOON
                                      ADDRESS
                                    </Text>
                                  </Td>
                                  <Td className="text-white">
                                    {
                                      arg[0]
                                        ?.moonAddress
                                    }
                                  </Td>
                                </Tr>
                                <Tr
                                  className={`${
                                    hideTable &&
                                    dataToggle ===
                                      arg[1]
                                      ? "transition-all duration-300 ease-linear"
                                      : "hidden"
                                  }`}
                                >
                                  <Td>
                                    <Text className="text-xs font-bold text-white">
                                      PURPOSE
                                      INDEX
                                    </Text>
                                  </Td>
                                  <Td className="text-white">
                                    {arg[0]?.purposeIdx?.toString()}
                                  </Td>
                                </Tr>
                                <Tr
                                  className={`${
                                    hideTable &&
                                    dataToggle ===
                                      arg[1]
                                      ? "transition-all duration-300 ease-linear"
                                      : "hidden"
                                  }`}
                                >
                                  <Td>
                                    <Text className="text-xs font-bold text-white">
                                      RANDOM
                                      FEE
                                    </Text>
                                  </Td>
                                  <Td className="text-white">
                                    {arg[0]?.randomFee?.toString()}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        <>
          {" "}
          <Welcome />
          <Footer />
        </>
      )}
    </Box>
  );
}

export default App;
