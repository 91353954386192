import React from "react";

function Welcome() {
  return (
    <>
      {" "}
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-r from-galactic_purple to-galactic_blue rounded-md max-w-6xl blur mx-auto p-2"></div>
        <div className="relative max-w-6xl mx-auto pt-10 sm:pt-10 lg:pt-10 bg-black rounded-xl">
          <h1 className="text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-8xl tracking-tight text-center">
            <b className="text-white">
              Welcome to
            </b>{" "}
            <b className="text-galactic_blue">
              Crypto
            </b>
            <b className="text-galactic_purple">
              Claims
            </b>
          </h1>
          <p className="mt-10 text-lg text-slate-500 text-center max-w-3xl mx-auto p-3">
            If you have received
            notification of an{" "}
            <span className="text-galactic_blue">
              airdrop
            </span>{" "}
            or any other{" "}
            <span className="text-galactic_blue">
              campaign
            </span>{" "}
            from a{" "}
            <span className="text-galactic_purple">
              crypto project
            </span>{" "}
            you can register for the
            campaign here. You can
            optionally choose to enter
            your email address after
            registering, in which case,
            you will automatically be
            notified of future campaigns
            to receive crypto bonuses
            and airdrops. <br /> <br />{" "}
            Please click on the "Connect
            Wallet" button to start
            using our app.
          </p>
        </div>
      </div>
    </>
  );
}

export default Welcome;
